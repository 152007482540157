import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import EmptyLoader from './EmptyLoader';
import {bindActionCreators} from 'redux';
import * as loginActions from '../../actions/loginActions';
import {twentyOneCfrConfig} from "../../constants/appConfig";

class ProtectedRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inactivityTimer: 0,
            heartbeatTimer: 0,

            logoutFired: false,
        };

        this.restartInactivityTimer = this.restartInactivityTimer.bind(this);
    }

    componentDidMount() {
        this.checkUserLoggedIn();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkUserLoggedIn();
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    restartInactivityTimer() {
        const {inactivityTimer, heartbeatTimer} = this.state;
        const {currentUser, actions} = this.props;

        let autoLogoutInterval = currentUser.userData.role.autoLogoutInterval;

        if (!autoLogoutInterval) {
            autoLogoutInterval = twentyOneCfrConfig.autoLogoutInterval;
        }

        // autoLogoutInterval = 1 / 6;

        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
        }

        const timer = setTimeout(() => {
            const {logoutFired} = this.state;

            if (logoutFired) {
                return;
            }

            actions.logoutUser(1);

            this.setState({
                logoutFired: true,
            })
        }, autoLogoutInterval * 60 * 1000);

        this.setState({
            inactivityTimer: timer,
        })
    };

    checkUserLoggedIn() {
        const {currentUser, actions} = this.props;
        const {inactivityTimer, heartbeatTimer} = this.state;

        if (currentUser.fetchingInfo || currentUser.loginInProgress) {
            // Do nothing
            return;
        }

        if (!currentUser.loggedIn) {
            // actions.savePrevRoute(prevRoute.pathname);
            this.clearTimers();

            if (!currentUser.forceLogin) {
                actions.forceUserLogin();
            }

            return;
        }

        if (!inactivityTimer) {
            this.restartInactivityTimer();
            window.addEventListener('load', this.restartInactivityTimer);
            document.addEventListener('mousemove', this.restartInactivityTimer);
            document.addEventListener('keypress', this.restartInactivityTimer);
            document.addEventListener('click', this.restartInactivityTimer);
        }

        // Heartbeat timer
        if (!heartbeatTimer) {
            actions.sendHeartbeat();

            const timer = setInterval(() => {
                // Check last sync time from localstorage
                const lastSyncTime = localStorage.getItem('lastSyncTime');
                const currentTime = Date.now();
                const syncThreshold = 20 * 1000;

                if (!lastSyncTime || currentTime - lastSyncTime >= syncThreshold) {
                    localStorage.setItem('lastSyncTime', currentTime.toString());
                    actions.sendHeartbeat();
                }
            }, 20 * 1000);

            this.setState({
                heartbeatTimer: timer,
            });
        }
    }

    clearTimers() {
        const {inactivityTimer, heartbeatTimer} = this.state;

        if (inactivityTimer) {
            window.removeEventListener('remove', this.restartInactivityTimer);
            document.removeEventListener('mousemove', this.restartInactivityTimer);
            document.removeEventListener('keypress', this.restartInactivityTimer);
            document.removeEventListener('click', this.restartInactivityTimer);
            clearTimeout(inactivityTimer);
        }

        if (heartbeatTimer) {
            clearInterval(heartbeatTimer);
        }
    }

    render() {
        const {children, currentUser} = this.props;

        // if (currentUser.loginInProgress || currentUser.fetchingInfo) {
        //     return (
        //         <EmptyLoader/>
        //     );
        // }

        if (currentUser.forceLogin) {
            return (
                <Navigate
                    to="/login"
                />
            );
        }

        return (
            <div className="protected-page">
                {children}
            </div>
        );
    }
}

ProtectedRoute.defaultProps = {
    actions: {},
    children: null,
    currentUser: {},
};

ProtectedRoute.propTypes = {
    actions: PropTypes.object,
    children: PropTypes.element,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
