import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import * as loginActions from "../../../actions/loginActions";
import classNames from "classnames";
import { IoMdArrowDropdownCircle } from "react-icons/io";

function AdminMenu({ currentUser }) {
  const location = useLocation();
  // TODO from role permissions
  const canManageUsers = true;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative px-2 py-2 grid grid-cols-2">
      <div>
        <p className="text-sm ml-2 mb-1">Administration</p>
      </div>

      <div className="absolute right-1 -top-1.5 admin-menu dropdown dropdown-hover dropdown-end">
        <div className="btn btn-ghost p-1">
          <div onClick={() => setIsOpen(!isOpen)}>
            <IoMdArrowDropdownCircle className="w-5 h-5 inline" />
          </div>
        </div>
        <ul className={classNames("p-0 text-black shadow menu dropdown-content bg-base-100 rounded-sm w-48", {
          "hidden": !isOpen,
          "block": isOpen,
        })}>
          {canManageUsers && (
            <li className="text-sm">
              <Link to={"/manage/users"}>Manage Users</Link>
            </li>
          )}
          <li className="text-sm">
            <Link to={"/manage/places"}>Manage Places</Link>
          </li>
          <li className="text-sm">
            <Link to={"/manage/customers"}>Manage Customers</Link>
          </li>
          <li className="text-sm">
            <Link to={"/towers/bulk_import"}>Bulk Import</Link>
          </li>
        </ul>
      </div>


      {/* {canManageUsers && (
        <div className="grid grid-cols-1 gap-0">
          <Link to={"/manage/users"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/manage/users"),
              "bg-slate-200" : (location.pathname === "/manage/users"),
            })}>
              <div>
                <FaUsersCog className="w-4 h-4 inline mr-2" />
            </div>
            <div className="mt-1 text-sm">
                  Manage Users
                </div>
              </div>
            </Link>

          <Link to={"/manage/places"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/manage/places"),
              "bg-slate-200" : (location.pathname === "/manage/places"),
            })}>
              <div>
                <FaMapPin className="w-4 h-4 inline mr-2" />
              </div>
            <div className="mt-1 text-sm">
                  Manage Places
                </div>
              </div>
            </Link>

          <Link to={"/manage/customers"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/manage/customers"),
              "bg-slate-200" : (location.pathname === "/manage/customers"),
            })}>
              <div>
                <HiBuildingOffice2 className="w-4 h-4 inline mr-2" />
              </div>
            <div className="mt-1 text-sm">
                  Manage Customers
                </div>
              </div>
            </Link>

          <Link to={"/towers/bulk_import"}>
            <div className={classNames({
              "p-1 pl-2 ml-1 flex flex-row text-black rounded-lg": true, 
              "hover:bg-slate-300": (location.pathname !== "/towers/bulk_import"),
              "bg-slate-200" : (location.pathname === "/towers/bulk_import"),
            })}>
              <div>
                <CiImport className="w-4 h-4 inline mr-2"/>
              </div>
              <div className="mt-1 text-sm">
                  Bulk Import
              </div>
            </div>
          </Link>
        </div>
      )} */}
    </div>
  );
}

AdminMenu.defaultProps = {
  actions: {},
  currentUser: {},
};

AdminMenu.propTypes = {
  actions: PropTypes.object,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
