import { cloneDeep } from "lodash";
import {
  ADD_PEDESTAL_DESIGN_SUCCESS,
  DEACTIVATE_PEDESTAL_DESIGN_SUCCESS,
  GET_PEDESTAL_DESIGNS_ERROR,
  GET_PEDESTAL_DESIGNS_STARTING,
  GET_PEDESTAL_DESIGNS_SUCCESS,
  UPDATE_PEDESTAL_DESIGN_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function pedestalDesignsReducer(
  state = initialState.pedestalDetails,
  action
) {

  const sortByOrder = (newState) => { };
  switch (action.type) {
    case GET_PEDESTAL_DESIGNS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_PEDESTAL_DESIGNS_SUCCESS: {
      const newState = cloneDeep(state);
      const ids = [];
      const map = {};
      const poleIds = [];
      const polesMap = {};
      const poleBasedMembers = {};

      action.data.pedestals.forEach((pedestalDetail) => {
        const { id: pedestalId, poleId, memberType } = pedestalDetail;

        if (!polesMap[poleId]) {
          poleIds.push(poleId);
          polesMap[poleId] = pedestalDetail.pole;
        }

        if (!poleBasedMembers[poleId]) {
          poleBasedMembers[poleId] = {
            Pole: [],
            Strut: [],
          };
        }

        poleBasedMembers[poleId][memberType].push(pedestalId);

        ids.push(pedestalId);
        map[pedestalId] = pedestalDetail;
      });
      newState.ids = ids;
      newState.map = map;
      newState.poleIds = poleIds;
      newState.polesMap = polesMap;
      newState.poleBasedMembers = poleBasedMembers;
      newState.loading = false;
      newState.loaded = true;
      sortByOrder(newState);
      return newState;
    }

    case ADD_PEDESTAL_DESIGN_SUCCESS: {
      const newState = cloneDeep(state);
      const { pedestals } = action.data;

      const pedestalId = pedestals.id;
      newState.map[pedestalId] = pedestals;
      newState.ids.push(pedestalId);

      newState.ids.sort((peds1Id, peds2Id) => {
        const peds1 = newState.map[peds1Id];
        const peds2 = newState.map[peds2Id];

        return peds1.id - peds2.id;
      });

      return newState;
    }

    case UPDATE_PEDESTAL_DESIGN_SUCCESS: {
      const newState = cloneDeep(state);
      const { pedestals } = action.data;

      const pedestalId = pedestals.id;
      newState.map[pedestalId] = pedestals;
      sortByOrder(newState);
      return newState;
    }

    case DEACTIVATE_PEDESTAL_DESIGN_SUCCESS: {
      const newState = cloneDeep(state);
      const { pedestalId } = action;

      newState.ids = newState.ids.filter((pedsId) => {
        return pedestalId !== pedsId;
      });
      return newState;
    }

    case GET_PEDESTAL_DESIGNS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.error ? action.error : "Failed to retrive Pedestal Data";

      return newState;
    }
    default: {
      return state;
    }
  }
}
