import { addServiceRequest, deactivateServiceRequest, updateServiceRequest } from "../actions/mobileTowers/serviceRequestsActions";

const initialState = {
  currentUser: {
    prevRoute: "",

    loading: false,
    loaded: false,

    refetchInfo: false,
    fetchingInfo: true,
    forceLogin: false,
    loggedIn: false,
    loginInProgress: false,

    error: "",
    loggedInElsewhere: false,

    userData: {},
    tokenData: {},

    userId: "",
    token: "",
  },

  clientInfo: {
    loading: false,
    loaded: false,
    data: {},
  },

  fileUploadStatus: {
    uploading: false,
    uploaded: false,
    error: null,
    file: null,
  },

  files: {
    fileId: {
      loading: false,
      loaded: false,
      error: null,

      file: {},
    },
  },

  fullScreenStatus: {
    enabled: false,
  },

  updateClientInfo: {
    saving: false,
    saved: false,
    error: null,
  },

  addMobileTower: {
    saving: false,
    saved: false,
    error: null,
    newMobileTower: null,
  },

  updateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  roles: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  modules: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addRole: {
    saving: false,
    saved: false,
    error: null,
    newRole: null,
  },

  updateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  users: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addUser: {
    saving: false,
    saved: false,
    error: null,
    newUser: null,
  },

  updateUser: {
    userId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateCategory: {
    categoryId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  userData: {
    map: {},
  },

  auditLogs: {
    ids: [],
    map: {},
    pagedIds: [],
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  toastNotification: {
    id: 0,
    data: null,
  },

  mobileTowers: {
    ids: [],
    inactiveIds: [],
    map: {},
    defectsMap: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  regions: {
    ids: [],
    inactiveIds: [],
    userAddress: null,
    companyId: 0,
    finalHierarchy: {},
    showInternational: false,
    treeState: {},
    map: {},
    hierarchy: {},
    towersMap: {},
    towerCounts: {},
    towersData: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  regionTowers: {
    placeId: {
      loading: false,
      loaded: false,
      allLoaded: false,
      towerIds: [],
      towersMap: {},
    },
  },

  searchSuggestions: {
    query: {
      loading: false,
      loaded: false,
      error: null,

      suggestions: [],
      links: {},
      resultsData: {},
    },
  },

  forgotPassword: {
    saving: false,
    saved: false,
    error: null,
  },

  resetPassword: {
    saving: false,
    saved: false,
    error: null,
    newPassword: null,
  },

  tenants: {
    ids: [],
    inactiveIds: [],
    map: {},
    typeWise: {
      GSM: [],
      RRU: [],
      "Micro-wave": [],
    },

    typeWiseSr: {
      GSM: [],
      RRU: [],
      "Micro-wave": [],
    },

    typeWiseSpare: {
      GSM: [],
      RRU: [],
      "Micro-wave": [],
    },

    heightWise: {},
    heightWiseSr: {},
    heightWiseSpare: {},

    legWise: {},
    legWiseSr: {},
    legWiseSpare: {},
    poleWise: {},
    poleWiseSr: {},
    poleWiseSpare: {},

    existingIds: [],
    srIds: [],
    spareIds: [],

    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  addTenant: {
    saving: false,
    saved: false,
    error: null,
    newTenant: null,
  },

  updateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  places: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPlace: {
    saving: false,
    saved: false,
    error: null,
    newPlace: null,
  },

  updatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  defects: {
    ids: [],
    map: {},
    groups: [],
    groupWiseMap: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  recommendations: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addRecommendation: {
    saving: false,
    saved: false,
    error: null,
    newRecommendation: null,
  },

  updateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addImageLabel: {
    saving: false,
    saved: false,
    error: null,
  },

  addTowerChecklist: {
    saving: false,
    saved: false,
    error: null,
  },

  members: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  addMember: {
    saving: false,
    saved: false,
    error: null,
    newMember: null,
  },

  updateMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  gussetEndPlates: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  addGussetEndPlate: {
    saving: false,
    saved: false,
    error: null,
    newGussetEndPlate: null,
  },

  updateGussetEndPlate: {
    gussetEndPlateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateGussetEndPlate: {
    gussetEndPlateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateGussetEndPlate: {
    gussetEndPlateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addButtJoint: {
    saving: false,
    saved: false,
    error: null,
    newButtJoint: null,
  },

  buttJoints: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  updateButtJoint: {
    buttJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateButtJoint: {
    buttJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateButtJoint: {
    buttJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  flangeJoints: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  addFlangeJoint: {
    saving: false,
    saved: false,
    error: null,
    newFlangeJoint: null,
  },

  updateFlangeJoint: {
    flangeJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateFlangeJoint: {
    flangeJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateFlangeJoint: {
    flangeJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addTowerSiteReport: {
    saving: false,
    saved: false,
    error: null,
  },

  addTowerRetrofittingReport: {
    saving: false,
    saved: false,
    error: null,
  },

  addWCCReport: {
    saving: false,
    saved: false,
    error: null,
  },

  addTowerDrawing: {
    saving: false,
    saved: false,
    error: null,
  },

  userAccess: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addEntityAccess: {
    saving: false,
    saved: false,
    error: null,
    newEntityAccess: null,
  },

  uploadClientLogo: {
    id: {
      loading: false,
      loaded: false,
      error: null,
      ClientLogo: {},
    },
  },

  companies: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addCompany: {
    saving: false,
    saved: false,
    error: null,
    newCompany: null,
  },

  updateCompany: {
    companyId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateCompany: {
    companyId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateCompany: {
    companyId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  panelDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPanelDetail: {
    saving: false,
    saved: false,
    error: null,
    newPanelData: null,
  },

  updatePanelDetail: {
    panelId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePanelDetail: {
    panelId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  towerAnalysis: {
    towerId: {
      analysisType: {
        loading: false,
        loaded: false,

        data: [],
      },
    },
  },

  towerAnalysisRtp: {
    towerId: {
      analysisTypeRtp: {
        loading: false,
        loaded: false,

        data: [],
      },
    },
  },

  addTowerCompany: {
    saving: false,
    saved: false,
    error: null,
    newCompany: null,
  },

  activateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  materialSpecifications: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  updateMaterialSpecification: {
    materialSpecificationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  userPreferences: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
  },

  updateUserPreference: {
    userPreferenceId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  auditsData: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addAudit: {
    saving: false,
    saved: false,
    error: null,
    newAudit: null,
  },

  updateAudit: {
    auditId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateAudit: {
    auditId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  towerDesigns: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  towerDesignFiles: {
    designId: {
      files: {},
      treeState: {},
      loading: false,
      loaded: false,
      error: null,
    },
  },

  addTowerDesigns: {
    saving: false,
    saved: false,
    error: null,
    newMobileTowerDesign: null,
  },

  updateTowerDesigns: {
    designId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateTowerDesigns: {
    designId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateTowerDesigns: {
    designId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  calculatedForces: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addCalculatedForces: {
    saving: false,
    saved: false,
    error: null,
    newCalculatedForce: null,
  },

  updateCalculatedForces: {
    calId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateCalculatedForces: {
    calId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  connectionBolts: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addConnectionBolts: {
    saving: false,
    saved: false,
    error: null,
    newCalculatedForce: null,
  },

  updateConnectionBolts: {
    connectionId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateConnectionBolts: {
    connectionId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  poleDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPoleDetail: {
    saving: false,
    saved: false,
    error: null,
    newPoleData: null,
  },

  updatePoleDetail: {
    poleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePoleDetail: {
    poleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  poleMembers: {
    ids: [],
    inactiveIds: [],
    map: {},
    poleIds: [],
    polesMap: {},
    poleBasedMembers: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: "",
  },

  addPoleMember: {
    saving: false,
    saved: false,
    error: null,
    newPoleMember: null,
  },

  updatePoleMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activatePoleMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePoleMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  foundationForcePoleDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    poleIds: [],
    polesMap: {},
    poleBasedMembers: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addFoundationForcePoleDetail: {
    saving: false,
    saved: false,
    error: null,
    newForcePole: null,
  },

  updateFoundationForcePoleDetail: {
    forcePoleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateFoundationForcePoleDetail: {
    forcePoleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  poleCableLadderDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPoleCableLadder: {
    saving: false,
    saved: false,
    error: null,
    poleCable: null,
  },

  updatePoleCableLadder: {
    poleCableId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePoleCableLadder: {
    poleCableId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  pedestalDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    poleIds: [],
    polesMap: {},
    poleBasedMembers: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPedestalDetail: {
    saving: false,
    saved: false,
    error: null,
    newPedestalDetail: null,
  },

  updatePedestalDetail: {
    pedestalId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePedestalDetail: {
    pedestalId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  utilizationDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addUtilizationDetail: {
    saving: false,
    saved: false,
    error: null,
    newUtilizationDetail: null,
  },

  updateUtilizationDetail: {
    utilizationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateUtilizationDetail: {
    utilizationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  towerDataReports: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },
  
  serviceRequests: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  serviceRequestsSummary: {
    stateNames: [],
    statsByState: {},
    stateIdsByName: {},
    loading: false,
    loaded: false,
    error: null,
  },

  addServiceRequest: {
    saving: false,
    saved: false,
    error: null,
    newServiceRequest: null,
  },

  updateServiceRequest: {
    serviceRequestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateServiceRequest: {
    serviceRequestId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  poleSections: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },


};

export default initialState;
