import { clone } from "lodash";
import {
  UPDATE_POLE_MEMBER_ERROR,
  UPDATE_POLE_MEMBER_STARTING,
  UPDATE_POLE_MEMBER_SUCCESS,
} from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function updatePoleMemberReducer(
  state = initialState.updatePoleMember,
  action
) {
  switch (action.type) {
    case UPDATE_POLE_MEMBER_STARTING: {
      const { memberId } = action;
      const newState = { ...state };

      if (!newState[memberId]) {
        newState[memberId] = { ...initialState.updatePoleMember.memberId };
      }

      newState[memberId] = {
        ...newState[memberId],
        saving: true,
        saved: false,
        error: null
      };

      return newState;
    }


    case UPDATE_POLE_MEMBER_SUCCESS: {
      const { memberId, data } = action;
      const newState = { ...state };

      if (!newState[memberId]) {
        newState[memberId] = { ...initialState.updatePoleMember.memberId };
      }

      newState[memberId] = {
        ...newState[memberId],
        saving: false,
        saved: true,
        error: null,
        data: data
      };

      return newState;
    }


    case UPDATE_POLE_MEMBER_ERROR: {
      const { memberId, err } = action;
      const newState = { ...state };

      if (!newState[memberId]) {
        newState[memberId] = { ...initialState.updatePoleMember.memberId };
      }

      newState[memberId] = {
        ...newState[memberId],
        saving: false,
        saved: false,
        error: err
      };

      return newState;
    }

    
    default: {
      return state;
    }

  }
}
