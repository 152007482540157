import { cloneDeep } from "lodash";
import { ADD_PEDESTAL_DESIGN_ERROR, ADD_PEDESTAL_DESIGN_STARTING, ADD_PEDESTAL_DESIGN_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function addPedestalDesignReducer(state=initialState.addPedestalDetail,action) {
    switch(action.type){
        case ADD_PEDESTAL_DESIGN_STARTING: {
            const newState = cloneDeep(state)
            newState.saving = true;
            newState.saved = false;
            newState.error = null;
            return newState;
        }

        case ADD_PEDESTAL_DESIGN_SUCCESS : {
            const newState = cloneDeep(state)
            newState.newPedestalDetail = action.data;
            newState.saving = false;
            newState.saved = true;
            newState.error = null;
            
            return newState;
        }

        case ADD_PEDESTAL_DESIGN_ERROR: {
            const newState = cloneDeep(state)
            newState.saving = false;
            newState.saved = false;
            newState.error = action.error ? action.error : "Failed to Add Pedestal Design Data";

            return newState;
        }

        default: {
            return state;
        }
    }
}