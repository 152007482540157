import { cloneDeep, sortBy } from "lodash";
import { ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS, DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR, DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS, GET_FOUNDATION_FORCE_POLE_DETAILS_ERROR, GET_FOUNDATION_FORCE_POLE_DETAILS_STARTING, GET_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS, UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS } from "../../../constants/actionTypes";
import initialState from "../../../store/initialState";

export default function foundationForcePoleReducer(state = initialState.foundationForcePoleDetails, action) {
    const sortByOrder = (newState) => { };
    switch (action.type) {
        case GET_FOUNDATION_FORCE_POLE_DETAILS_STARTING: {
            const newState = cloneDeep(state)
            newState.loading = true;
            newState.loaded = false;
            newState.error = null;
            return newState;
        }

        case GET_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state)
            const ids = [];
            const map = {};
            const poleIds = [];
            const polesMap = {};
            const poleBasedMembers = {};

            action.data.forcePolesData.forEach((forcePoleData) => {
                const {id: forcePoleId, poleId, memberType} = forcePoleData;
                if (!polesMap[poleId]) {
                    poleIds.push(poleId);
                    polesMap[poleId] = forcePoleData.pole;
                }

                if (!poleBasedMembers[poleId]) {
                    poleBasedMembers[poleId] = {
                        Pole: [],
                        Strut: [],
                        Horizontal: [],
                    };
                }

                poleBasedMembers[poleId][memberType].push(forcePoleId);

                ids.push(forcePoleId);
                map[forcePoleId] = forcePoleData;
            });

            newState.ids = ids;
            newState.map = map;
            newState.poleIds = poleIds;
            newState.polesMap = polesMap;
            newState.poleBasedMembers = poleBasedMembers;
            newState.loading = false;
            newState.loaded = true;
            return newState;
        }

        case ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            const { forcePolesData } = action.data
            const forcePoleId = forcePolesData.id;

            newState.map[forcePoleId] = forcePolesData;
            newState.ids.push(forcePoleId);

            newState.ids.sort((force1Id, force2Id) => {
                const force1 = newState.map[force1Id];
                const force2 = newState.map[force2Id];

                return force1.id - force2.id;
            });

            return newState;

        }

        case UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS: {
            const newState = cloneDeep(state);
            const { forcePolesData } = action.data;
            const forcePoleId = forcePolesData.id;

            newState.map[forcePoleId] = forcePolesData;

            sortByOrder(newState)
            return newState;
        }

        case DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS: {

            const newState = cloneDeep(state);
            const { forcePoleId } = action;

            newState.ids = newState.ids.filter((forceId) => {
                return forcePoleId !== forceId;
            });
            return newState;
        }

        case GET_FOUNDATION_FORCE_POLE_DETAILS_ERROR: {
            const newState = cloneDeep(state)
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.error ? action.error : "Failed to retrive Foundation Force Pole Data";

            return newState;
        }

        default: {
            return state;
        }
    }
}