import { cloneDeep } from 'lodash';
import {
    GET_TENANTS_STARTING,
    GET_TENANTS_SUCCESS,
    GET_TENANTS_ERROR,
    ADD_TENANT_SUCCESS,
    UPDATE_TENANT_SUCCESS,
    DEACTIVATE_TENANT_SUCCESS,
    ACTIVATE_TENANT_SUCCESS,
} from '../../../constants/actionTypes';
import initialState from '../../../store/initialState';
import { getTenantEPA } from '../../../utils/mobileTowers';

export default function tenantsReducer(state = initialState.tenants, action) {
    switch (action.type) {
        case GET_TENANTS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_TENANTS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [], existingIds = [], srIds = [], spareIds = [];
            const map = {};

            const typeWiseTenants = {
                'GSM': [],
                'RRU': [],
                'Micro-wave': [],
            };

            const typeWiseSrTenants = {
                'GSM': [],
                'RRU': [],
                'Micro-wave': [],
            };

            const typeWiseSpareTenants = {
                'GSM': [],
                'RRU': [],
                'Micro-wave': [],
            };

            const legWiseTenants = {
                legName: {
                    'GSM': [],
                    'RRU': [],
                    'Micro-wave': [],
                },
            };

            const poleWiseTenants = {
                poleName: {
                    'GSM': [],
                    'RRU': [],
                    'Micro-wave': [],
                },
            };

            const legWiseSrTenants = {}, legWiseSpareTenants = {};
            const poleWiseSrTenants = {}, poleWiseSpareTenants = {};

            const heightWiseTenants = {}, heightWiseSrTenants = {}, heightWiseSpareTenants = {};
            const heights = ['0 - 2.5', '2.5 - 5', '5 - 7.5', '7.5 - 10', '10 - 12.5', '12.5 - 15', '15 - 17.5', '17.5 - 20', '>20'];

            heights.forEach((label) => {
                heightWiseTenants[label] = {
                    'GSM': [],
                    'RRU': [],
                    'Micro-wave': [],
                };

                heightWiseSrTenants[label] = {
                    'GSM': [],
                    'RRU': [],
                    'Micro-wave': [],
                };

                heightWiseSpareTenants[label] = {
                    'GSM': [],
                    'RRU': [],
                    'Micro-wave': [],
                };
            });

            action.data.tenants.forEach((tenant) => {
                const tenantId = tenant.id;
                map[tenantId] = tenant;

                if (tenant.isActive !== 1) {
                    return;
                }

                let tenantEpa = getTenantEPA(tenant);
                tenantEpa = tenantEpa.toFixed(3);
                tenant.epa = tenantEpa;

                ids.push(tenantId);

                const { tenantType, antennaType, legName, heightFromTop, pole } = tenant;
                // console.log('pole', pole);
                let poleName = pole ? pole.name : '';
                // console.log('poleName', poleName);

                if (!poleWiseTenants[poleName]) {
                    // console.log('poleWiseTenants[poleName]', poleWiseTenants[poleName]);
                    poleWiseTenants[poleName] = cloneDeep(poleWiseTenants.poleName);
                }

                if (!legWiseTenants[legName]) {
                    legWiseTenants[legName] = cloneDeep(legWiseTenants.legName);
                }

                if (!legWiseSrTenants[legName]) {
                    legWiseSrTenants[legName] = cloneDeep(legWiseTenants.legName);
                }

                if (!poleWiseSrTenants[poleName]) {
                    poleWiseSrTenants[poleName] = cloneDeep(poleWiseTenants.poleName);
                }

                if (!legWiseSpareTenants[legName]) {
                    legWiseSpareTenants[legName] = cloneDeep(legWiseTenants.legName);
                }

                if (!poleWiseSpareTenants[poleName]) {
                    poleWiseSpareTenants[poleName] = cloneDeep(poleWiseTenants.poleName);
                }

                if (tenantType === 'Existing') {
                    existingIds.push(tenant.id);
                    typeWiseTenants[antennaType].push(tenant.id);
                    legWiseTenants[legName][antennaType].push(tenant);
                    poleWiseTenants[poleName][antennaType].push(tenant);

                    if (heightFromTop <= 2.5) {
                        heightWiseTenants['0 - 2.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 5) {
                        heightWiseTenants['2.5 - 5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 7.5) {
                        heightWiseTenants['5 - 7.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 10) {
                        heightWiseTenants['7.5 - 10'][antennaType].push(tenant);
                    }else if (heightFromTop <= 12.5) {
                        heightWiseTenants['10 - 12.5'][antennaType].push(tenant);
                    }else if (heightFromTop <= 15) {
                        heightWiseTenants['12.5 - 15'][antennaType].push(tenant);
                    }else if (heightFromTop <= 17.5) {
                        heightWiseTenants['15 - 17.5'][antennaType].push(tenant);
                    }else if (heightFromTop <= 20) {
                        heightWiseTenants['17.5 - 20'][antennaType].push(tenant);
                    } else {
                        heightWiseTenants['>20'][antennaType].push(tenant);
                    }
                } else if (tenantType === 'Service Request') {
                    srIds.push(tenant.id);
                    typeWiseSrTenants[antennaType].push(tenant.id);
                    legWiseSrTenants[legName][antennaType].push(tenant);
                    poleWiseSrTenants[poleName][antennaType].push(tenant);

                    if (heightFromTop <= 2.5) {
                        heightWiseSrTenants['0 - 2.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 5) {
                        heightWiseSrTenants['2.5 - 5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 7.5) {
                        heightWiseSrTenants['5 - 7.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 10) {
                        heightWiseSrTenants['7.5 - 10'][antennaType].push(tenant);
                    }else if (heightFromTop <= 12.5) {
                        heightWiseSrTenants['10 - 12.5'][antennaType].push(tenant);
                    }else if (heightFromTop <= 15) {
                        heightWiseSrTenants['12.5 - 15'][antennaType].push(tenant);
                    }else if (heightFromTop <= 17.5) {
                        heightWiseSrTenants['15 - 17.5'][antennaType].push(tenant);
                    }else if (heightFromTop <= 20) {
                        heightWiseSrTenants['17.5 - 20'][antennaType].push(tenant);
                    } else {
                        heightWiseSrTenants['>20'][antennaType].push(tenant);
                    }
                } else if (tenantType === 'Spare') {
                    spareIds.push(tenant.id);
                    typeWiseSpareTenants[antennaType].push(tenant.id);
                    legWiseSpareTenants[legName][antennaType].push(tenant);
                    poleWiseSpareTenants[poleName][antennaType].push(tenant);
                    
                    if (heightFromTop <= 2.5) {
                        heightWiseSpareTenants['0 - 2.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 5) {
                        heightWiseSpareTenants['2.5 - 5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 7.5) {
                        heightWiseSpareTenants['5 - 7.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 10) {
                        heightWiseSpareTenants['7.5 - 10'][antennaType].push(tenant);
                    } else if (heightFromTop <= 12.5) {
                        heightWiseSpareTenants['10 - 12.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 15) {
                        heightWiseSpareTenants['12.5 - 15'][antennaType].push(tenant);
                    } else if (heightFromTop <= 17.5) {
                        heightWiseSpareTenants['15 - 17.5'][antennaType].push(tenant);
                    } else if (heightFromTop <= 20) {
                        heightWiseSpareTenants['17.5 - 20'][antennaType].push(tenant);
                    } else {
                        heightWiseSpareTenants['>20'][antennaType].push(tenant);
                    }
                }
                const {epa, shieldingFactor} = tenant;
                let shieldingFactorEpa = epa * shieldingFactor;
                tenant.shieldingFactorEpa = shieldingFactorEpa;
            });

            // console.log('poleWiseTenants', poleWiseTenants);

            newState.ids = ids;
            newState.map = map;
            newState.existingIds = existingIds;
            newState.srIds = srIds;
            newState.spareIds = spareIds;

            delete legWiseTenants.legName;

            newState.legWise = legWiseTenants;
            newState.poleWise = poleWiseTenants;
            newState.legWiseSr = legWiseSrTenants;
            newState.poleWiseSr = poleWiseSrTenants;
            newState.legWiseSpare = legWiseSpareTenants;
            newState.poleWiseSpare = poleWiseSpareTenants;

            newState.typeWise = typeWiseTenants;
            newState.typeWiseSr = typeWiseSrTenants;
            newState.typeWiseSpare = typeWiseSpareTenants;

            newState.heightWise = heightWiseTenants;
            newState.heightWiseSr = heightWiseSrTenants;
            newState.heightWiseSpare = heightWiseSpareTenants;

            newState.loading = false;
            newState.loaded = true;

            return newState;
        }

        case GET_TENANTS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to  retrieve tenants.';
            return newState;
        }

        case ADD_TENANT_SUCCESS: {
            const newState = cloneDeep(state);
            const { tenant } = action.data;
            const tenantId = tenant.id;

            let tenantEpa = getTenantEPA(tenant);
            tenantEpa = tenantEpa.toFixed(3);
            tenant.epa = tenantEpa;

            newState.map[tenantId] = tenant;
            newState.ids.push(tenantId);

            newState.ids.sort((ten1Id, ten2Id) => {
                const ten1 = newState.map[ten1Id];
                const ten2 = newState.map[ten2Id];
                return ten1.id - ten2.id;
            });

            return newState;
        }

        case UPDATE_TENANT_SUCCESS: {
            const newState = cloneDeep(state);
            const { tenant } = action.data;
            const tenantId = tenant.id;

            let tenantEpa = getTenantEPA(tenant);
            tenantEpa = tenantEpa.toFixed(3);
            tenant.epa = tenantEpa;

            newState.map[tenantId] = tenant;
            return newState;
        }

        case ACTIVATE_TENANT_SUCCESS: {
            const newState = cloneDeep(state);
            const { tenantId } = action;
            newState.ids.push(tenantId);

            newState.inactiveIds = newState.inactiveIds.filter((pId) => {
                return tenantId !== pId;
            });

            if (newState.map[tenantId]) {
                newState.map[tenantId].isActive = 1;
            }

            // sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_TENANT_SUCCESS: {
            const newState = cloneDeep(state);
            const { tenantId } = action;
            newState.inactiveIds.push(tenantId);

            newState.ids = newState.ids.filter((pId) => {
                return tenantId !== pId;
            });

            if (newState.map[tenantId]) {
                newState.map[tenantId].isActive = 0;
            }

            // sortByOrder(newState);
            return newState;
        }

        default: {
            return state;
        }
    }
}