import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_MOBILE_TOWER_BY_ID_ERROR,
    GET_MOBILE_TOWER_BY_ID_STARTING,
    GET_MOBILE_TOWER_BY_ID_SUCCESS,
    GET_MOBILE_TOWERS_ERROR,
    GET_MOBILE_TOWERS_STARTING,
    GET_MOBILE_TOWERS_SUCCESS,
    ADD_MOBILE_TOWER_SUCCESS,
    ADD_CLIENT_LOGO_SUCCESS,
    UPDATE_MOBILE_TOWER_SUCCESS,
    GET_INACTIVE_MOBILE_TOWERS_SUCCESS,
    DEACTIVATE_MOBILE_TOWER_SUCCESS,
    ACTIVATE_MOBILE_TOWER_SUCCESS,
    ADD_TOWER_SITEREPORT_SUCCESS,
    ADD_TOWER_RETROFITTING_SUCCESS,
    ADD_WORK_COMPLETION_REPORT_SUCCESS,
    ADD_TOWER_CHECKLIST_SUCCESS,
    ADD_TOWER_DRAWING_SUCCESS,
    ADD_MOBILE_TOWER_IMAGE_SUCCESS,
    DELETE_MOBILE_TOWER_IMAGE_SUCCESS,
    ADD_RECOMMENDATION_SUCCESS,
    UPDATE_DEFECTS_SUCCESS,
    ADD_IMAGE_LABEL_SUCCESS,
    ADD_TOWER_COMPANY_SUCCESS,
    DEACTIVATE_RECOMMENDATION_SUCCESS,
    UPDATE_RECOMMENDATION_SUCCESS,
    ACTIVATE_RECOMMENDATION_SUCCESS,
    ACTIVATE_TENANT_SUCCESS,
    DEACTIVATE_TENANT_SUCCESS,
    ADD_TENANT_SUCCESS,
    UPDATE_TENANT_SUCCESS,
    ADD_AUDIT_DATA_SUCCESS,
    UPDATE_AUDIT_DATA_SUCCESS,
    DEACTIVATE_AUDIT_DATA_SUCCESS,

    ADD_PANEL_DETAILS_SUCCESS,
    UPDATE_PANEL_DETAILS_SUCCESS,
    DEACTIVATE_PANEL_DETAILS_SUCCESS,

    ADD_MEMBER_SUCCESS,
    UPDATE_MEMBER_SUCCESS,
    DEACTIVATE_MEMBER_SUCCESS,

    ADD_CONNECTION_BOLTS_SUCCESS,
    UPDATE_CONNECTION_BOLTS_SUCCESS,
    DEACTIVATE_CONNECTION_BOLTS_SUCCESS,

    ADD_CALCULATED_FORCE_SUCCESS,
    UPDATE_CALCULATED_FORCE_SUCCESS,
    DEACTIVATE_CALCULATED_FORCE_SUCCESS, UPDATE_SERVICE_REQUEST_SUCCESS,
    ADD_SERVICE_REQUEST_SUCCESS,
    ADD_POLE_MEMBER_SUCCESS,
    UPDATE_POLE_MEMBER_SUCCESS,
    ADD_POLE_DETAILS_SUCCESS,
    UPDATE_POLE_DETAILS_SUCCESS,
    ADD_POLE_CABLE_LADDER_SUCCESS,
    UPDATE_POLE_CABLE_LADDER_SUCCESS,
    ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS,
    UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS,
    ADD_PEDESTAL_DESIGN_SUCCESS,
    UPDATE_PEDESTAL_DESIGN_SUCCESS,
    DEACTIVATE_PEDESTAL_DESIGN_SUCCESS,
    ADD_UTILIZATION_SUCCESS,
    UPDATE_UTILIZATION_SUCCESS,
    DEACTIVATE_UTILIZATION_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder = (newState) => {
    newState.ids.sort((cat1Id, cat2Id) => {
        const tower1 = newState.map[cat1Id];
        const tower2 = newState.map[cat2Id];
        return tower1.displayOrder - tower2.displayOrder;
    });

    newState.inactiveIds.sort((cat1Id, cat2Id) => {
        const tower1 = newState.map[cat1Id];
        const tower2 = newState.map[cat2Id];
        return tower1.displayOrder - tower2.displayOrder;
    });
}

export default function mobileTowersReducer(state = initialState.mobileTowers, action) {
    // console.log(action);

    switch (action.type) {
        case GET_MOBILE_TOWER_BY_ID_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MOBILE_TOWER_BY_ID_SUCCESS: {
            const newState = cloneDeep(state);
            const { mobileTowerId, data } = action;
            newState.map[mobileTowerId] = data.mobileTower;
            newState.loading = false;
            newState.loaded = true;

            newState.defectsMap[mobileTowerId] = {};
            data.mobileTower.defects.forEach(d => {
                newState.defectsMap[mobileTowerId][d.id] = true;
            });

            return newState;
        }

        case GET_MOBILE_TOWER_BY_ID_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve mobile tower details by ID.';
            return newState;
        }

        case GET_MOBILE_TOWERS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_MOBILE_TOWERS_SUCCESS: {
            const newState = cloneDeep(state);
            const ids = [];

            action.data.mobileTowers.forEach((mobileTower) => {
                const mobileTowerId = mobileTower.id;
                ids.push(mobileTowerId);
                newState.map[mobileTowerId] = mobileTower;
            });

            newState.ids = ids;

            //newState.page = action.data.page;
            //newState.pages = action.data.pages;
            //newState.limit = action.data.limit;
            //newState.total = action.data.total;
            //newState.isLastPage = newState.page >= newState.pages;
            newState.loading = false;
            newState.loaded = true;

            sortByOrder(newState);

            return newState;
        }

        case GET_INACTIVE_MOBILE_TOWERS_SUCCESS: {
            const newState = cloneDeep(state);
            const inactiveIds = [];

            action.data.mobileTowers.forEach((mobileTower) => {
                const mobileTowerId = mobileTower.id;
                inactiveIds.push(mobileTowerId);
                newState.map[mobileTowerId] = mobileTower;
            });

            newState.inactiveIds = inactiveIds;

            sortByOrder(newState);
            return newState;
        }

        case GET_MOBILE_TOWERS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve mobileTowers.';
            return newState;
        }

        case ADD_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const { mobileTower } = action.data;
            const mobileTowerId = mobileTower.id;

            // Push new location data
            newState.map[mobileTowerId] = mobileTower;
            newState.ids.push(mobileTowerId);

            // Sort by display order
            sortByOrder(newState);

            return newState;
        }

        case UPDATE_SERVICE_REQUEST_SUCCESS: {
            const { serviceRequest } = action.data;
            const { mobileTowerId } = serviceRequest;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            const mobileTower = newState.map[mobileTowerId];
            mobileTower.serviceRequests = mobileTower.serviceRequests.map(sr => {
                if (sr.id === serviceRequest.id) {
                    return serviceRequest;
                }

                return sr;
            })

            return newState;
        }

        case UPDATE_DEFECTS_SUCCESS: {
            const { mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            console.log(mobileTowerId);
            const { mobileTower } = action.data;
            const newState = cloneDeep(state);

            newState.map[mobileTowerId] = mobileTower;
            console.log('Resetting defects map');
            console.log(mobileTower);

            newState.defectsMap[mobileTowerId] = {};
            mobileTower.defects.forEach(d => {
                newState.defectsMap[mobileTowerId][d.id] = true;
            });

            console.log(newState.defectsMap);
            return newState;
        }

        case ADD_RECOMMENDATION_SUCCESS:
        case UPDATE_RECOMMENDATION_SUCCESS:
        case ACTIVATE_RECOMMENDATION_SUCCESS:
        case DEACTIVATE_RECOMMENDATION_SUCCESS:
        case ADD_TENANT_SUCCESS:
        case UPDATE_TENANT_SUCCESS:
        case ACTIVATE_TENANT_SUCCESS:
        case ADD_MOBILE_TOWER_IMAGE_SUCCESS:
        case DEACTIVATE_TENANT_SUCCESS:
        case ADD_AUDIT_DATA_SUCCESS:
        case UPDATE_AUDIT_DATA_SUCCESS:
        case DEACTIVATE_AUDIT_DATA_SUCCESS:
        case ADD_PANEL_DETAILS_SUCCESS:
        case UPDATE_PANEL_DETAILS_SUCCESS:
        case DEACTIVATE_PANEL_DETAILS_SUCCESS:
        case ADD_MEMBER_SUCCESS:
        case UPDATE_MEMBER_SUCCESS:
        case DEACTIVATE_MEMBER_SUCCESS:
        case ADD_CONNECTION_BOLTS_SUCCESS:
        case UPDATE_CONNECTION_BOLTS_SUCCESS:
        case DEACTIVATE_CONNECTION_BOLTS_SUCCESS:
        case ADD_CALCULATED_FORCE_SUCCESS:
        case UPDATE_CALCULATED_FORCE_SUCCESS:
        case ADD_POLE_MEMBER_SUCCESS:
        case UPDATE_POLE_MEMBER_SUCCESS:
        case ADD_POLE_DETAILS_SUCCESS:
        case UPDATE_POLE_DETAILS_SUCCESS:
        case ADD_POLE_CABLE_LADDER_SUCCESS:
        case UPDATE_POLE_CABLE_LADDER_SUCCESS:
        case ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS:
        case UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS:
        case DEACTIVATE_CALCULATED_FORCE_SUCCESS:
        case ADD_PEDESTAL_DESIGN_SUCCESS:
        case UPDATE_PEDESTAL_DESIGN_SUCCESS:
        case DEACTIVATE_PEDESTAL_DESIGN_SUCCESS:
        case ADD_UTILIZATION_SUCCESS:
        case UPDATE_UTILIZATION_SUCCESS:
        case DEACTIVATE_UTILIZATION_SUCCESS:
            {
                const { data } = action;
                if (!data.mobileTower) {
                    return state;
                }

                const mobileTowerId = data.mobileTower.id;
                if (!state.map[mobileTowerId]) {
                    return state;
                }

                const newState = cloneDeep(state);
                newState.map[mobileTowerId] = data.mobileTower;
                return newState;
            }

        case DELETE_MOBILE_TOWER_IMAGE_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId].files = newState.map[mobileTowerId].files.filter((f) => {
                return f.id !== data.mediaObject.id;
            });

            return newState;
        }

        case ADD_TOWER_CHECKLIST_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;

            return newState;
        }

        case ADD_IMAGE_LABEL_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;
            return newState;
        }

        case ADD_TOWER_SITEREPORT_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;

            // if (newState.map[mobileTowerId]) {
            //     newState.map[mobileTowerId].siteReport = [
            //         data.mediaObject,
            //     ];
            // }

            return newState;
        }

        case ADD_TOWER_RETROFITTING_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;

            return newState;
        }

        case ADD_WORK_COMPLETION_REPORT_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;
            return newState;
        }

        case ADD_TOWER_DRAWING_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;

            // if (newState.map[mobileTowerId]) {
            //     newState.map[mobileTowerId].drawing = [
            //         data.mediaObject,
            //     ];
            // }

            return newState;
        }

        case ADD_CLIENT_LOGO_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            if (newState.map[mobileTowerId]) {
                newState.map[mobileTowerId].clientLogo = [
                    data.mediaObject,
                ];
            }

            return newState;
        }

        case UPDATE_MOBILE_TOWER_SUCCESS: {
            const { mobileTower } = action.data;
            if (!mobileTower) {
                return state;
            }

            const newState = cloneDeep(state);
            const mobileTowerId = mobileTower.id;
            newState.map[mobileTowerId] = mobileTower;

            sortByOrder(newState);
            return newState;
        }

        case ACTIVATE_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const { mobileTowerId } = action;
            newState.ids.push(mobileTowerId);

            newState.inactiveIds = newState.inactiveIds.filter((cId) => {
                return mobileTowerId !== cId;
            });

            if (newState.map[mobileTowerId]) {
                newState.map[mobileTowerId].isActive = 1;
            }

            sortByOrder(newState);
            return newState;
        }

        case DEACTIVATE_MOBILE_TOWER_SUCCESS: {
            const newState = cloneDeep(state);
            const { mobileTowerId } = action;
            newState.inactiveIds.push(mobileTowerId);

            newState.ids = newState.ids.filter((cId) => {
                return mobileTowerId !== cId;
            });

            if (newState.map[mobileTowerId]) {
                newState.map[mobileTowerId].isActive = 0;
            }

            sortByOrder(newState);
            return newState;
        }

        case ADD_SERVICE_REQUEST_SUCCESS: {
            const { data, mobileTowerId } = action;
            if (!state.map[mobileTowerId]) {
                return state;
            }

            const newState = cloneDeep(state);
            newState.map[mobileTowerId] = data.mobileTower;

            return newState;
        }


        case ADD_TOWER_COMPANY_SUCCESS: {
            const { mobileTowerId, data } = action;

            if (!state.map[mobileTowerId]) {
                return state;
            }

            if (!data.mobileTower) {
                return state;
            }

            const newState = cloneDeep(state);
            if (data.mobileTower.company) {
                newState.map[mobileTowerId].company = data.mobileTower.company;
                newState.map[mobileTowerId].companyId = data.mobileTower.company.id;
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}
