import initialState from "../../../store/initialState";
import {
    GET_SERVICE_REQUESTS_SUMMARY_ERROR,
    GET_SERVICE_REQUESTS_SUMMARY_STARTING,
    GET_SERVICE_REQUESTS_SUMMARY_SUCCESS,
} from "../../../constants/actionTypes";
import {cloneDeep} from "lodash";

export default function serviceRequestsSummaryReducer(
    state = initialState.serviceRequestsSummary,
    action
) {
    switch (action.type) {
        case GET_SERVICE_REQUESTS_SUMMARY_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_SERVICE_REQUESTS_SUMMARY_SUCCESS: {
            const newState = cloneDeep(state);
            newState.stateNames = action.data.stateNames;
            newState.statsByState = action.data.statsByState;
            newState.stateIdsByName = action.data.stateIdsByName;
            newState.loading = false;
            newState.loaded = true;
            return newState;
        }

        case GET_SERVICE_REQUESTS_SUMMARY_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            return newState;
        }
        default: {
            return state;
        }
    }
}
